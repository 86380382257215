import React from "react";

import { LanguageProvider } from "../components/languageProvider";
import { PortfolioPage as Portfolio } from "../components/portfolioPage";

const PortfolioPage = ({ pageContext }) => {
  return (
    <LanguageProvider language="en">
      <Portfolio pageContext={pageContext} />
    </LanguageProvider>
  );
};

export default PortfolioPage;
